export enum PageParams {
  Register = '/autentykacja/rejestracja',
  Login = '/autentykacja/logowanie',
  Game = '/gra',
  Profile = '/profil',
  Posts = '/zadania',
  Rules = '/zasady',
  PrivacyPolice = '/polityka-prywatnosci',
  WaitingRoom = '/poczekalnia',
  ResetPassword = '/reset',
  PremiumPayment = '/premium',
  PaymentSuccess = '/sukces-platnosci',
  PaymentFailed = '/brak-platnosci',
  Page404 = '/404',
  Home = '/',
}
